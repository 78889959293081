import PropTypes from 'prop-types'
import React from 'react'
import Policy1 from '../../assets/images/Privacy Policy-05_26_2020-1.png'
import Policy2 from '../../assets/images/Privacy Policy-05_26_2020-2.png'
import Policy3 from '../../assets/images/Privacy Policy-05_26_2020-3.png'
import Policy4 from '../../assets/images/Privacy Policy-05_26_2020-4.png'
import Policy5 from '../../assets/images/Privacy Policy-05_26_2020-5.png'
import Policy6 from '../../assets/images/Privacy Policy-05_26_2020-6.png'
import Policy7 from '../../assets/images/Privacy Policy-05_26_2020-7.png'
import Policy8 from '../../assets/images/Privacy Policy-05_26_2020-8.png'
import Policy9 from '../../assets/images/Privacy Policy-05_26_2020-9.png'
import Policy10 from '../../assets/images/Privacy Policy-05_26_2020-10.png'
import {Link} from 'react-router-dom'

const Signup = ({ fetchStatus }) => {
  return (
		<div>
			<Link to="/">Home</Link>
			<img src={Policy1} alt="Policy1" style={{ width: '100%', height: '100%'}}/>
			<img src={Policy2} alt="Policy1" style={{ width: '100%', height: '100%'}}/>
			<img src={Policy3} alt="Policy1" style={{ width: '100%', height: '100%'}}/>
			<img src={Policy4} alt="Policy1" style={{ width: '100%', height: '100%'}}/>
			<img src={Policy5} alt="Policy1" style={{ width: '100%', height: '100%'}}/>
			<img src={Policy6} alt="Policy1" style={{ width: '100%', height: '100%'}}/>
			<img src={Policy7} alt="Policy1" style={{ width: '100%', height: '100%'}}/>
			<img src={Policy8} alt="Policy1" style={{ width: '100%', height: '100%'}}/>
			<img src={Policy9} alt="Policy1" style={{ width: '100%', height: '100%'}}/>
			<img src={Policy10} alt="Policy1" style={{ width: '100%', height: '100%'}}/>
		</div>
	)
}

Signup.displayName = 'Signup'

Signup.propTypes = {
  fetchStatus: PropTypes.string.isRequired,
}
 
export default Signup;