import getOr from 'lodash/fp/getOr'
import { ofType } from 'redux-observable'
import { of } from 'rxjs'
import { switchMap, map, catchError } from 'rxjs/operators'
import Fetch from '../../../constants/fetch'

//Actions
export const CREATE_USER = 'Signup/Create_User'
export const CREATE_USER_SUCCESS = 'Signup/Create_User_Success'
export const CREATE_USER_ERROR = 'Signup/Create_User_Error'

// Action Creators
export const createUser = payload => ({ payload, type: CREATE_USER})
export const createUserSuccess = payload => ({ payload, type: CREATE_USER_SUCCESS})
export const createUserError = payload => ({ payload, type: CREATE_USER_ERROR})

// Initial State
export const initialState = {
  fetchStatus: Fetch.DEFAULT,
  data: []
}

// Selectors
export const selectFetchStatus = getOr(initialState.fetchStatus, 'signup.fetchStatus')


// Epics
export const epics = {
  create: (action$) =>
  action$.pipe(
    ofType(CREATE_USER),
    switchMap(action => {
      // make an api call to create user
    }),
    map(data => {
      createUserSuccess(data)
    }),
    catchError(err => {
      of(createUserError(err))
    })
  )
}

// Reducer
export default function (state = initialState, action) {
  switch (action.type) {
		case CREATE_USER: {
			return {
				...state,
				fetchStatus: Fetch.PENDING,
			}
		}
		case CREATE_USER_SUCCESS: {
			return {
        ...state,
        data: action.payload,
				fetchStatus: Fetch.SUCCESS,
			}
    }
    case CREATE_USER_ERROR: {
      return {
				...state,
				fetchStatus: Fetch.ERROR,
			}
    }
		default:
			return state
	}
}