import React from 'react';
import { Provider } from 'react-redux'
import ReactDOM from 'react-dom';
import { BrowserRouter as Router, Route } from 'react-router-dom'
import App from './App';
import PrivacyPolicy from './components/privacy-policy/component'
import configureStore from './store'

const store = configureStore()

ReactDOM.render(
	<React.StrictMode>
		<Provider store={store}>
			<Router>
        <Route component={App} path='/' exact />
        <Route component={PrivacyPolicy} path='/privacy-policy' exact />
			</Router>
		</Provider>
	</React.StrictMode>,
	document.getElementById('root')
)

