import React from 'react';
import SnackMock from './assets/images/iPhone.png'
import iOSDownload from './assets/images/iOSDownload.png'
import './App.css'
import {Link} from 'react-router-dom'

const App = () => {
  return (
		<div className='main'>
			<div className='header'>
				<h1 className='header-title'>Snack</h1>
				<p className='header-description'>AI-powered News Summary</p>
				<p className='header-description'>Stay relevant and remain on top of the things that matter to you</p>
			</div>
			<div className='content-container'>
				<div className='iPhone-container'>
					<img src={SnackMock} alt='iPhone Mock' className='iPhone' />
				</div>
				<div className='download-container'>
					<a href='https://apps.apple.com/us/app/snack/id1513095838?ls=1' target='_blank' rel='noopener noreferrer'>
						<img src={iOSDownload} alt='iOS Download Button' className='download-button' />
					</a>
				</div>
			</div>
			<div className='footer'>
				<Link to='/privacy-policy' title='Privacy Policy' className='link'>
					Privacy Policy
				</Link>
				<Link to='/privacy-policy' title='Terms of use' className='link'>
					Terms
				</Link>
				<a href='mailto:getsnacknews@gmail.com' title='Contact' className='link'>
					Contact
				</a>
			</div>
		</div>
	)
}

export default App;