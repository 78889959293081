import rootReducer from './root-reducer';
import rootEpics from './epics';
import initialState from './initial-state';
import {createEpicMiddleware} from 'redux-observable';
import {createStore, applyMiddleware} from 'redux';

const epicMiddleware = createEpicMiddleware();

export default function configureStore() {
  const store = createStore(
    rootReducer,
    initialState,
    applyMiddleware(epicMiddleware),
  );

  epicMiddleware.run(rootEpics);

  return store;
}
